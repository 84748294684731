import React from 'react'

const Homea = () => {
  return (
    <div>
      {/* <!-- ========== Start NAVBAR Section ========== --> */}
    <header class="section-navbar">
        <div class="container">
            <div class="navbar-brand">
                <a href="../Pages/index.html">
                    <img src="../images/logo.png" alt="logo mian eduhub image" width="40%" height="auto" />
                </a>
            </div>
            <nav class="navbar">
                <ul>
                    <li class="nav-item"><a href="../Pages/index.html" class="nav-link">home</a></li>
                    <li class="nav-item"><a href="../Pages/About.html" class="nav-link">about</a></li>
                    <li class="nav-item"><a href="../Pages/Services.html" class="nav-link">services</a></li>
                    <li class="nav-item"><a href="../Pages/Blog.html" class="nav-link">blog</a></li>
                    <li class="nav-item"><a href="#section-contact--homepage" class="nav-link">contact us</a></li>
                </ul>
            </nav>
        </div>
    </header>

    {/* <!-- ========== End NAVBAR Section ========== -->

    <!-- ========== Start HERO Section ========== --> */}
    <main>
        <div class="section-hero">
            <div class="container grid grid-two--cols">
                <div class="section-hero--content">
                    <p class="hero-subheading">
                        EMPOWERING LIFELONG LEARNING
                    </p>
                    <h1 class="hero-heading">
                        Unlock Your Potential With Mian EduHub
                    </h1>
                    <p class="hero-para">
                        Welcome to Mian EduHub, your gateway to knowledge and skill
                        development. We are dedicated to providing high-quality,
                        accessible education for learners of all ages and backgrounds
                    </p>
                    <div class="hero-btn">
                        <a href="../Pages/" class="btn">Learn with us</a>
                    </div>
                </div>
                <div class="section-hero--image" data-aos="fade-up">
                    <figure>
                        <img src="../images/main-hero.png" alt="hero image" />
                    </figure>
                </div>
            </div>
        </div>

        <div class="custom-shape-divider-bottom-1715421855">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                preserveAspectRatio="none">
                <path
                    d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                    opacity=".25" class="shape-fill"></path>
                <path
                    d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                    opacity=".5" class="shape-fill"></path>
                <path
                    d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                    class="shape-fill"></path>
            </svg>
        </div>
    </main>
    {/* <!-- ========== End HERO Section ========== -->
    <!-- ========== Start About Section ========== --> */}
    <section class="section-about">
        <div class="container">
            <h2 class="section-common-heading">About Mian EduHub
            </h2>
            <p class="section-common-subheading">
                Guiding Your Learning Journey Through Research,Design,and Development Excellence.
            </p>
        </div>
        <div class="container grid grid-three--cols">
            <div class="about-div" data-aos="fade-up" data-aos-delay="300">
                <div class="icon">
                    <img src="../images/research.png" alt="research logo" />
                </div>
                <h3 class="section-common--title">
                    Research
                </h3>
                <p>
                    We start by learning what you need. We look at what's new and
                    exciting in learning and choose topics that match what you want to
                    learn.
                </p>
            </div>
            <div class="about-div" data-aos="fade-up" data-aos-delay="600">
                <div class="icon">
                    <img src="../images/design.png" alt="research logo" />
                </div>
                <h3 class="section-common--title">
                    Design
                </h3>
                <p>
                    Next, we make the lessons. We make them fun and interesting for you.
                    We make sure they work for different ways people like to learn.
                </p>
            </div>
            <div class="about-div" data-aos="fade-up">
                <div class="icon">
                    <img src="../images/Coding.png" alt="research logo" />
                </div>
                <h3 class="section-common--title">
                    Development
                </h3>
                <p>
                    After that, we turn the lessons into things you can use online. We
                    use the latest tools to make sure they work well and make you happy.
                </p>
            </div>
        </div>
    </section>

    {/* <!-- ========== End About Section ========== -->

    <!-- ========== Start COURSE Section ========== --> */}
    <section class="section-course">
        <div class="container">
            <h2 class="section-common-heading">Explore Our Courses
            </h2>
            <p class="section-common-subheading">
                Discover a variety of courses across different categories.
            </p>
        </div>
        <div class="container grid grid-four--cols">
            <div class="course-div">
                <div class="icon">
                    <i class="fa-solid fa-code"></i>
                </div>
                <h3 class="section-common--title">JS Development</h3>
                <p>
                    Creating dynamic, interactive web applications for user engagement.
                </p>
            </div>
            <div class="course-div">
                <div class="icon"><i class="fa-solid fa-laptop-code"></i></div>
                <h3 class="section-common--title">Python</h3>
                <p>
                    Versatile language for problem-solving and application development.
                </p>
            </div>
            <div class="course-div">
                <div class="icon"><i class="fa-solid fa-laptop-code"></i></div>
                <h3 class="section-common--title">c++ Development</h3>
                <p>Powerful, efficient coding language for software development.</p>
            </div>
            <div class="course-div">
                <div class="icon"><i class="fa-solid fa-laptop-code"></i></div>
                <h3 class="section-common--title">Java Development</h3>
                <p>Cross-platform application development with Java programming.</p>
            </div>
            <div class="course-div">
                <div class="icon"><i class="fa-solid fa-laptop-code"></i></div>
                <h3 class="section-common--title">SQL Development</h3>
                <p>
                    Effective data management and retrieval using SQL database language.
                </p>
            </div>
            <div class="course-div">
                <div class="icon"><i class="fa-solid fa-laptop-code"></i></div>
                <h3 class="section-common--title">HTML Development</h3>
                <p>Web content structure with essential markup language.</p>
            </div>
            <div class="course-div">
                <div class="icon"><i class="fa-solid fa-laptop-code"></i></div>
                <h3 class="section-common--title">CSS Development</h3>
                <p>Styling web elements for visually appealing websites.</p>
            </div>
            </div>
    </section>
    {/* <!-- ========== End COURSE Section ========== -->

    <!-- ========== Start Why Choose Section ========== --> */}

    <section class="section-why--choose">
        <div class="container">
            <h2 class="section-common-heading">
                Why Choose Mian EduHub
            </h2>
            <p class="section-common-subheading">
                Choose Mian EduHub for a holistic, enriching learning experience that empowers you to achieve your
                goals.
            </p>
        </div>
        <div class="container grid grid-three--cols">
            <div class="choose-left--div text-align--right">
                <div class="why-choose--div">
                    <p class="common-text--highlight">
                        1
                    </p>
                    <h3 class="section-common--title"> Expert Instructors</h3>
                    <p>
                        Learn from experienced teachers who are passionate about sharing
                        knowledge and guiding you toward success in your learning journey.
                    </p>
                </div>
                <div class="why-choose--div">
                    <p class="common-text--highlight">
                        2
                    </p>
                    <h3 class="section-common--title"> Expert Instructors</h3>
                    <p>
                        Learn from experienced teachers who are passionate about sharing
                        knowledge and guiding you toward success in your learning journey.
                    </p>
                </div>
                <div class="why-choose--div">
                    <p class="common-text--highlight">
                        3
                    </p>
                    <h3 class="section-common--title"> Expert Instructors</h3>
                    <p>
                        Learn from experienced teachers who are passionate about sharing
                        knowledge and guiding you toward success in your learning journey.
                    </p>
                </div>
            </div>
            {/* <!-- start center div --> */}
            <div class="choose-center--div" data-aos="zoom-in">
                <figure>
                    <img src="../images/IMG_20230910_164223.webp" alt="my pic" />

                </figure>
            </div>
            {/* <!--  start right div --> */}
            <div class="choose-right--div text-align--left">
                <div class="why-choose--div">
                    <p class="common-text--highlight">4</p>
                    <h3 class="section-common--title">
                        Expert Instructors
                    </h3>
                    <p> Learn from experienced teachers who are passionate about sharing
                        knowledge and guiding you toward success in your learning journey.</p>
                </div>
                <div class="why-choose--div">
                    <p class="common-text--highlight">5</p>
                    <h3 class="section-common--title">
                        Expert Instructors
                    </h3>
                    <p> Learn from experienced teachers who are passionate about sharing
                        knowledge and guiding you toward success in your learning journey.</p>
                </div>
                <div class="why-choose--div">
                    <p class="common-text--highlight">6</p>
                    <h3 class="section-common--title">
                        Expert Instructors
                    </h3>
                    <p> Learn from experienced teachers who are passionate about sharing
                        knowledge and guiding you toward success in your learning journey.</p>
                </div>
            </div>

        </div>
    </section>
    {/* <!-- ========== End Why Choose Section ========== -->

    <!-- ========== Start Contact home section ========== --> */}
    <div class="section-contact--homepage" id="section-contact--homepage">
        <div class="container grid grid-two--cols">
            <div class="contact-content">
                <h2 class="contact-title">
                    Let's revolutionize the way you study
                </h2>
                <p>Join our free bootcamps to know us better</p>
                <div class="btn">
                    <a href="./Home.html">
                        Start now<i class="fa-solid fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
            <div class="contact-image">
                <img src="../images/main.png" alt="a guy with coding" />
            </div>
        </div>
    </div>
    {/* <!-- ========== End Contact home section ========== -->

    <!-- ========== Start footer section ========== --> */}
    <footer>
        <div class="container grid grid-four--cols">
            <div class="footer-1--div">
                <div class="logo-brand">
                    <a href="./Home.html" class="footer-subheading">Mian EduHub</a>
                </div>
                <p> Let's revolutionize the way you study with mian EduHub</p>
                <div class="social-footer--icons">
                    <a href="./Home.html" target="-blank">
                        <i class="fa-brands fa-discord"></i>
                    </a>
                    <a href="./Home.html" target="-blank">
                        <i class="fa-brands fa-youtube"></i>
                    </a>
                    <a href="./Home.html" target="-blank">
                        <i class="fa-brands fa-instagram"></i>
                    </a>
                </div>
            </div>
            <div class="footer-2--div">
                <p class="footer-subheading">Courses</p>
                <ul>
                    <li><a href="./Home.html">
                            Html
                        </a></li>
                    <li><a href="./Home.html">
                            Css3
                        </a></li>
                    <li><a href="./Home.html">
                        JavaScript
                        </a></li>
                    <li><a href="./Home.html">
                            Bootstrap
                        </a></li>
                    <li><a href="./Home.html">
                            React.js
                        </a></li>
                </ul>
            </div>
            <div class="footer-3--div">
                <p class="footer-subheading">About</p>
                <ul>
                    <li><a href="./Home.html">
                            Menu
                        </a></li>
                    <li><a href="./Home.html">
                            Design
                        </a></li>
                    <li><a href="./Home.html">
                            Development
                        </a></li>
                    <li><a href="./Home.html">
                        Research    
                        </a></li>
                    <li><a href="./Home.html">
                         About 
                        </a></li>
                </ul>
            </div>
            <div class="footer-4--div">
                <p class="footer-subheading">Privacy</p>
                <ul>
                    <li><a href="./Home.html">
                           @ copyright 
                        </a></li>
                    <li><a href="./Home.html">
                            2024
                        </a></li>
                    <li><a href="./Home.html">
                            Pakistan
                        </a></li>
                    <li><a href="./Home.html">
                            Punjab
                        </a></li>
                    <li><a href="./Home.html">
                            Okara
                        </a></li>
                </ul>
            </div>
        </div>
        <div class="copyright">
            @ copyright 2024,Okara Punjab Pakistan
        </div>
    </footer>

    {/* <!-- ========== End footer section ========== --> */}

    </div>
  )
}

export default Homea
