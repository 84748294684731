
import './App.css';
import Homea from './Homea';

function App() {
  return (
    <div className="App">
     <Homea/>
    </div>
  );
}

export default App;
